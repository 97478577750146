// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import $ from "jquery";


// CONFIG SCROLLSPY
var scrollSpy = new bootstrap.ScrollSpy(document.body, {
  offset: $('#header').outerHeight() - 2
})


$(document).on("turbo:load", () => {
  // //
  // // Add smooth scrolling on all links inside the navbar
  $("#navbar a, .scrollto").on('click', function(event) {
    $('#navbar').removeClass('show');

    if (this.hash !== "") {
      event.preventDefault();
      var hash = this.hash;

      $('html, body').animate({
        scrollTop: (($(hash).offset().top)-($('#header').outerHeight() - 4))
      }, 800);
    }
  });
  //
  //
  // FIXED HEADER
  $(window).on('load scroll resize orientationchange', function () {
    if ($(document).scrollTop()) {
      $("#header").addClass("fixed-top shadow");
      $('#main').css({ 'margin-top': $('#header').height()+'px' });
    } else {
      $("#header").removeClass("fixed-top shadow");
      $('#main').css({ 'margin-top': '0px' });
    }
  });

});

